<template>
  <section class="tag-page-wrap">
    <PageHeader class="base-shadow" />
    <section class="base-page-section base-shadow">
      <div class="section-header">
        <span>部门树形数据</span>
        <div>
          <el-input
            class="mt-r"
            size="small"
            v-model="filterName"
            placeholder="请输入关键字搜索"
            clearable
            style="width: 250px"
          ></el-input>
          <el-button
            class="mt-r"
            :icon="loading ? 'el-icon-loading' : 'el-icon-refresh'"
            title="刷新数据"
            @click="onRefresh"
            size="small"
          ></el-button>
          <el-dropdown :hide-on-click="false" trigger="click">
            <span class="el-dropdown-link" title="设置">
              <i class="el-icon-more el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                ><el-checkbox v-model="toggleCheckbox"
                  >启用选择</el-checkbox
                ></el-dropdown-item
              >
              <el-dropdown-item
                ><el-checkbox v-model="toggleExpandAll"
                  >全部展开</el-checkbox
                ></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <!--  -->
      <el-tree
        class="department-tree"
        :data="treeData"
        :show-checkbox="toggleCheckbox"
        node-key="id"
        :props="defaultProps"
        :filter-node-method="filterNode"
        default-expand-all
        :default-expanded-keys="defaultExpandeds"
        :expand-on-click-node="false"
        draggable
        icon-class="el-icon-arrow-down"
        :allow-drop="allowDrop"
        :allow-drag="allowDrag"
        @node-drop="handleDrop"
        @node-expand="handleNodeExpand"
        @node-collapse="handleNodeCollapse"
        ref="tree"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <!-- 新增的表单 -->
          <el-row :gutter="10" v-if="data.parentId && data.key">
            <el-col :span="24"
              ><InlineForm
                v-model="data.name"
                @confirm="onConfirmAdd(node, data)"
                @cancel="onCancelAdd(node, data)"
            /></el-col>
            <!-- <el-col :span="12"
              ><InlineForm
                v-model="data.seq"
                @confirm="onConfirmAdd(node, data)"
                @cancel="onCancelAdd(node, data)"
            /></el-col> -->
          </el-row>

          <!-- 修改的表单 -->
          <el-row :gutter="10" v-if="data.isEdit">
            <el-col :span="24">
              <!-- hiddenConfirm hiddenCancel -->
              <InlineForm
                v-model="data.name"
                @confirm="onConfirmEdit(node, data)"
                @cancel="onCancelEdit(data)"
            /></el-col>
            <!-- <el-col :span="12"
              ><InlineForm
                v-model="data.seq"
                @confirm="onConfirmEdit(node, data)"
                @cancel="onCancelEdit(data)"
            /></el-col> -->
          </el-row>

          <template v-if="!data.key && !data.isEdit">
            <span style="padding-right: 15px">
              <i class="el-icon-rank drag-icon"></i>
              {{ node.label }}</span
            >
            <span
              class="custom-tree-control"
              :class="[data.showDelete ? 'show-control' : '']"
            >
              <el-button
                size="mini"
                icon="el-icon-plus"
                title="添加"
                v-if="checkPermission(['USER_ADMIN'])"
                @click="() => append(data)"
              >
              </el-button>
              <el-button
                v-if="data.id !== topItemId"
                type="info"
                size="mini"
                icon="el-icon-edit"
                :title="data.canUpdateChecking ? '权限检验中...' : '修改'"
                :loading="data.canUpdateChecking"
                @click="() => editItem(node)"
              >
              </el-button>
              <el-popover
                placement="top"
                width="200"
                v-model="data.showDelete"
                style="margin-left: 10px"
              >
                <p style="padding: 10px">是否确定要删除【{{ data.name }}】？</p>
                <div style="text-align: right; margin: 0">
                  <el-button
                    size="mini"
                    type="text"
                    :disabled="data.canDeleteChecking"
                    @click="data.showDelete = false"
                    >取消</el-button
                  >
                  <el-button
                    type="success"
                    size="mini"
                    :loading="data.canDeleteChecking"
                    @click="onComfirmDeleteItems(node, data)"
                    >{{
                      data.canDeleteChecking ? "权限检验中..." : "确定"
                    }}</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  title="删除"
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                >
                </el-button>
              </el-popover>
            </span>
          </template>
        </span>
      </el-tree>
    </section>
  </section>
</template>

<script>
import {
  GetDepartmentsTreeList,
  DeleteDepartmentById,
  PostDepartment,
  PutDepartmentById,
  GetUpdateCheck,
  GetDeleteCheck,
} from "./api";
import { ShowApiError } from "@/request/error";
import InlineForm from "@/components/InlineForm";
import { createUniqueString, compare } from "@/utils/common";
import dragEvent from "./mixins/dragEvent";
import { checkPermission } from "@/utils/auth";
import PageHeader from "@/components/PageHeader";
export default {
  components: {
    InlineForm,
    PageHeader,
  },
  mixins: [dragEvent],
  data() {
    return {
      loading: false,
      defaultProps: {
        children: "subs",
        label: "name",
      },
      defaultExpandeds: [],
      topItemId: null,
      toggleCheckbox: false,
      toggleExpandAll: true,
      treeData: [],
      filterName: "",
    };
  },
  created() {
    this.getDepartmentData();
  },
  watch: {
    filterName(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    checkPermission,
    createUniqueString,
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getDepartmentData() {
      this.loading = true;
      GetDepartmentsTreeList()
        .then((res) => {
          this.loading = false;
          this.treeData = this.formatData(res.data);
          this.topItemId = this.treeData[0].id;
          if (this.treeData.length > 0) {
            this.setDefaultExpandeds(this.treeData[0].id);
          }
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取部门数据异常", err);
        });
    },
    // 格式化数据
    formatData(data = []) {
      let results = [];
      if (data.length > 0) {
        data.forEach((item) => {
          results.push({
            ...item,
            subs: this.formatData(item.subs),
            canDeleteChecking: false,
            canUpdateChecking: false,
            canUpdate: false,
            canDelete: false,
            showDelete: false,
            isEdit: false,
          });
        });
      }
      return results.sort(compare("seq"));
    },
    onRefresh() {
      this.getDepartmentData();
    },
    // 新增部门数据
    append(data) {
      this.setDefaultExpandeds(data.id);
      data.subs.push({
        parentId: data.id,
        name: "",
        seq: data.subs.length + 1,
        key: createUniqueString(),
      });
    },
    editItem(node) {
      if (node.data.canUpdate) {
        node.data.isEdit = true;
        this.setDefaultExpandeds(node.data.id);
        return false;
      }
      node.data.canUpdateChecking = true;
      GetUpdateCheck(node.data.id)
        .then((res) => {
          node.data.canUpdate = res.data;
          node.data.canUpdateChecking = false;
          if (res.data) {
            this.setDefaultExpandeds(node.data.id);
            node.data.isEdit = true;
          } else {
            node.data.isEdit = false;
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          node.data.canUpdateChecking = false;
          this.$message.warning("权限校验异常，请稍后再试");
        });
    },
    onComfirmDeleteItems(node, data) {
      if (data.canDelete) {
        DeleteDepartmentById(data.id)
          .then(() => {
            this.onRefresh();
          })
          .catch((err) => {
            ShowApiError("操作请求错误", err);
          });
        return false;
      }
      data.canDeleteChecking = true;
      GetDeleteCheck(data.id)
        .then((res) => {
          data.canDeleteChecking = false;
          data.canDelete = res.data;
          if (res.data) {
            if (node.parent.data.id) {
              this.setDefaultExpandeds(node.parent.data.id);
            } else {
              this.setDefaultExpandeds(data.id);
            }
            DeleteDepartmentById(data.id)
              .then(() => {
                this.onRefresh();
              })
              .catch((err) => {
                ShowApiError("操作请求错误", err);
              });
          } else {
            this.$message.warning("没有权限执行此操作!");
            data.showDelete = false;
          }
        })
        .catch(() => {
          data.canDeleteChecking = false;
          data.showDelete = false;
          this.$message.warning("权限校验异常，请稍后再试");
        });
    },
    // 新增部分
    onConfirmAdd(node, data) {
      if (data.name && data.name !== "") {
        PostDepartment({
          parentId: data.parentId,
          seq: data.seq,
          name: data.name,
        })
          .then((res) => {
            // 将新建的成功的数据塞到数据里面去
            node.parent.data.subs.push({
              ...res.data,
              isEdit: false,
              showDelete: false,
            });
            // 然后将表单的那数据行移除
            node.parent.data.subs = node.parent.data.subs.filter(
              (item) => !item.key
            );
          })
          .catch((err) => {
            ShowApiError("新增部门失败", err);
          });
      } else {
        this.$message.warning("名称不能为空");
      }
    },
    onCancelAdd(node, data) {
      if (data.key && data.key !== "") {
        let position = null;
        node.parent.data.subs.forEach((item, index) => {
          if (item.key && item.key === data.key) {
            position = index;
          }
        });
        node.parent.data.subs.splice(position, 1);
      }
    },
    // 修改部分
    onConfirmEdit(node, data) {
      if (data.name === "") {
        this.$message.warning("名称不能为空");
        return false;
      }
      PutDepartmentById(data.id, {
        name: data.name,
        parentId: node.parent.data.id,
        seq: data.seq,
      })
        .then(() => {
          node.data.isEdit = false;
        })
        .catch((err) => {
          ShowApiError("修改失败", err);
        });
    },
    onCancelEdit(data) {
      data.isEdit = false;
      this.onRefresh();
    },
    // 修改数据
    putEditItem(id, params = {}) {
      PutDepartmentById(id, params)
        .then(() => {
          console.log("操作成功");
          // this.onRefresh();
        })
        .catch((err) => {
          this.onRefresh();
          ShowApiError("修改失败", err);
        });
    },
    // 节点展开
    handleNodeExpand(data) {
      this.setDefaultExpandeds(data.id);
    },
    handleNodeCollapse(data) {
      this.setDefaultExpandeds(data.id);
    },
    setDefaultExpandeds(id) {
      if (!this.defaultExpandeds.includes(id)) {
        this.defaultExpandeds.push(id);
      } else {
        this.defaultExpandeds = this.defaultExpandeds.filter(
          (item) => item !== id
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-page-wrap {
  border-radius: 4px;
}
.base-page-section {
  box-sizing: border-box;
  padding-top: 48px;
  margin: 10px;
  border-radius: 4px;
  position: relative;
}
.section-header {
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;
  height: 48px;
  font-size: 15px;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
  position: absolute;
  top: 0;
  left: 0;
  .refresh-btn {
    font-size: 18px;
    padding-left: 10px;
    cursor: pointer;
  }
}
.custom-tree-node {
  font-size: 16px;
  .custom-tree-control {
    padding-left: 15px;
    visibility: hidden;
  }
  &:hover {
    .custom-tree-control {
      visibility: visible;
    }
  }
  .show-control {
    visibility: visible;
  }
}
.department-tree {
  font-size: 15px;
  .drag-icon {
    cursor: move;
  }
  ::v-deep {
    .el-tree-node__content {
      height: 42px;
    }
  }
}
</style>